<template>
  <div class="content">
    <div class="fsection">
      <h1>Impressum</h1>
      <h2>Angaben gemäß § 5 TMG</h2>
      <p>
        <span style="font-weight: bold">Huber Health Care SE</span><br />
        Neue Straße 85<br />
        89073 Ulm
      </p>
      <p>
        Handelsregister: HRB 738785<br />
        Registergericht: Amtsgericht Ulm</p>
        <p>
          Geschäftsführender Direktor: Martin Huber
        </p>
        <p>
          Verwaltungsrat: Dr. Stefan Kienzle
        </p>
      <p>
        <span style="font-weight: bold">Kontakt</span><br />
        Telefon: +49 7335 9206 109<br />
        Telefax: +49 7335 9206 199<br />
        E-Mail: info@huber-health-care.com<br />
      </p>
      <p>
        <span style="font-weight: bold">Umsatzsteuer-ID</span><br />
        Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE 325 450 135
      </p>

      <p>
      <span style="font-weight: bold"
      >Verantwortlich für den Inhalt nach § 18 Abs. 2 MStV</span
      ><br />
        Martin Huber<br />
        Industrie- und Businesspark 213<br />
        Breitwiesen<br />
        73347 Mühlhausen
      </p>
      <p>
        <span style="font-weight: bold">EU-Streitschlichtung</span><br />
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:<br />
        https://ec.europa.eu/consumers/odr.<br />
        Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>

      <p>
      <span style="font-weight: bold"
      >Verbraucherstreitbeilegung/ Universalschlichtungsstelle</span
      ><br />
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
        einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
      <h2>Haftung für Inhalte</h2>
      <p>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
        Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
        als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
        Informationen zu überwachen oder nach Umständen zu forschen, die auf
        eine rechtswidrige Tätigkeit hinweisen.
      </p>
      <p>
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach
        den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
        jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
        möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
        diese Inhalte umgehend entfernen.
      </p>
      <h2>Haftung für Links</h2>
      <p>
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir
        keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine
        Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige
        Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
        Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
        Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
      </p>
      <p>
        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
        Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      </p>
      <h2>Urheberrecht</h2>
      <p>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
        unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
        Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
        bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
        Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
      </p>
      <p>
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
        Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
        gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
        werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
        Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImprintComponent",
};
</script>

<style scoped>
h1 {
  font-size: 2.5em;
  font-weight: 400;
  text-align: center;
  background-color: #f2f2f2;
}

h2 {
  margin-top: 20px;
  font-size: 1.7em;
  font-weight: 400;
  text-align: center;
  background-color: #f2f2f2;
}

p {
  font-size: 1.2em;
  font-weight: 300;
  margin: 0 auto 10px auto;
  text-align: justify;
}

@media only screen and (max-width: 502px) {
  .fsection {
    font-size: 0.8rem;
  }
}
</style>
