import axios from "axios";

const a = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL
});

a.interceptors.request.use(config => {
  if (localStorage.getItem("accessToken")) {
    config.headers.accessToken = localStorage.getItem("accessToken");
  }
  return config;
});

export default a;
