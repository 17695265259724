import apiClient from "../../../utils/apiClient";

export const getAllPublicTestCenters = () => {
  return apiClient.get("/public/testCenters");
};

export const getPublicTestCenter = testCenterId => {
  return apiClient.get(`/public/testCenters/${testCenterId}`);
};

export const getAllTenantTestCenters = (tenantId) => {
  return apiClient.get(`/public/testCenters?tenant=${tenantId}`);
};

export const getAllPatientTestCenters = (patientId) => {
  return apiClient.get(`/public/testCenters?patient=${patientId}`);
};

export const getBookingSlotUsage = (testCenterId, fromMoment, toMoment, includeExcluded = false, onlyExcluded = false) => {
  const extra = {};
  if (onlyExcluded) {
    extra.onlyExcluded = true;
  } else if (includeExcluded) {
    extra.includeExcluded = true;
  }
  return apiClient.get(`/public/testCenters/${testCenterId}/bookingSlotUsage`, {
    params: {
      from: fromMoment.toDate(),
      to: toMoment.toDate(),
      ...extra
    }
  });
};

export const checkSignupPassword = (testCenterId, password) => {
  return apiClient.get(`/public/testCenters/${testCenterId}/signupPassword/${encodeURIComponent(password)}`);
};
