import * as testCenterApi from "./testCenter.api";

const getInitialState = () => {
  return {
    testCenterListLoaded: false,
    testCenterList: []
  };
};

const state = getInitialState();
const getters = {
  testCenterList: state => state.testCenterList,
  testCenterListLoaded: state => state.testCenterListLoaded
};

const mutations = {
  resetState(state) {
    Object.assign(state, getInitialState());
  },

  setTestCenterList(state, payload) {
    state.testCenterList = payload;
  },
  setTestCenterListLoaded(state, payload) {
    state.testCenterListLoaded = payload;
  }
};

const actions = {
  loadPublicTestCenters({ commit }) {
    commit("setTestCenterListLoaded", false);
    testCenterApi
      .getAllPublicTestCenters()
      .then(res => {
        commit("setTestCenterList", res.data);
        commit("setTestCenterListLoaded", true);
      })
      .catch(() => {
        commit("setTestCenterList", []);
        commit("setTestCenterListLoaded", true);
      });
  },
  loadPatientTestCenters({ commit }, { patientId }) {
    commit("setTestCenterListLoaded", false);
    testCenterApi
      .getAllPatientTestCenters(patientId)
      .then(res => {
        commit("setTestCenterList", res.data);
        commit("setTestCenterListLoaded", true);
      })
      .catch(() => {
        commit("setTestCenterList", []);
        commit("setTestCenterListLoaded", true);
      });
  },
  loadTenantTestCenters({ commit }, { tenantId }) {
    commit("setTestCenterListLoaded", false);
    testCenterApi
      .getAllTenantTestCenters(tenantId)
      .then(res => {
        commit("setTestCenterList", res.data);
        commit("setTestCenterListLoaded", true);
      })
      .catch(() => {
        commit("setTestCenterList", []);
        commit("setTestCenterListLoaded", true);
      });
  }
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
