<template>
  <div id="app-header" class="pb-3">
    <b-navbar toggleable="lg" >
      <b-navbar-brand :href="logoPath"> <img
          src="../assets/huber_logo_new.png"
          alt="Huber Health Care SE - Check Box"
          class="site-logo"
      /></b-navbar-brand>

      <b-navbar-toggle id="scroll-down" target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" class="row justify-content-end" is-nav>
        <b-navbar-nav>
          <a class="btn-link" v-b-modal.imprint-modal>{{ $t("footer.imprint") }}</a>
          <a @click="openPdf('/privacy.pdf')" class="btn-link">{{ $t("signupPage.privacyPolicy") }}</a>
          <a @click="openPdf('/consent.pdf')" class="btn-link">{{ $t("signupPage.ConsentForm") }}</a>
          <a
              v-if="!(this.testCenter && this.testCenter.portalKioskMode)"
              @click="openPdf('/agb.pdf')"
              class="btn-link"
          >
            {{ $t("signupPage.agb") }}
          </a>

          <div style="text-align: center" class="locale-changer">

            <b-form-select  size="sm" style="width: 60px" v-model="$i18n.locale">
              <option v-for="(lang, i) in $i18n.availableLocales" :key="`Lang${i}`" :value="lang">
                {{ lang.toUpperCase() }}
              </option>

            </b-form-select>

          </div>
          <b-icon class="m-1 globeIcon" style="height: 18px;width: 18px" icon="globe2" variant="info"></b-icon>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-modal hide-footer size="xl" id="imprint-modal">
      <ImprintComponent />
    </b-modal>
    <b-modal hide-footer size="xl" ref="pdfModal" id="pdfModal">
      <iframe :src="selectedPath" class="pdfIframe"></iframe>
    </b-modal>
  </div>
</template>

<script>
import ImprintComponent from "../views/legal/components/ImprintComponent";
import {mapState} from "vuex";

export default {
  //eslint-disable-next-line
  name: "Header",
  components: {ImprintComponent},
  data(){
    return {
      selectedPath: ""
    };
  },
  computed: {
    ...mapState("signUp", ["testCenter"]),
    logoPath() {
      if (this.testCenter && this.testCenter.portalKioskMode) {
        return this.$route.fullPath;
      } else {
        return "/";
      }
    }
  },
  methods: {
    openPdf(url) {
      this.selectedPath = url;
      if (this.testCenter && this.testCenter.portalKioskMode) {
        this.$refs.pdfModal.show();
      } else {
        const routerData = this.$router.resolve({name: url});
        window.open(routerData.location.name, '_blank');
      }
    }
  }
};
</script>

<style scoped>
img.site-logo {
  width: 20rem;
  padding-top: 1.3rem;
}
@media only screen and (max-width: 990px){

  img.site-logo {
    width: 10rem;
    padding-top: 1.3rem;
  }
  #scroll-down{
    width: 15%;
  }
  #nav-collapse{
    text-align: center!important;
    display: block;

  }
  .globeIcon{
    align-self: center!important;
  }
}
#app-header{
  background-color: white;
  min-height: 4rem;
}

.btn-link {

  cursor: pointer;
  color: #9b9696;
  font-size: 20px;
  margin-right: 14px;

}

@media only screen and (min-width: 960px) {
 #nav-collapse {
    padding-top: 20px;
   padding-right: 30px;

  }
  .globeIcon{
    align-self: center!important;
  }

}


.btn-link:hover{
  cursor: pointer;
  color: #d4cece;
 text-decoration: none;
}
.headerContainer {
  text-shadow: 0px 0px 3px #FFFFFF;
  font-size: 11pt;
}
.pdfIframe {
  width: 100%;
  min-height: 1000px;
}
</style>
