<template>
  <b-modal
    :title="$t('internetExplorer.title')"
    ref="internetExplorerModal"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    style="z-index: 100"
  >
    <b-alert style="z-index: 100" show variant="danger">
      {{ $t("internetExplorer.description") }}
    </b-alert>
  </b-modal>
</template>

<script>
export default {
  name: "InternetExplorer.vue",

  mounted() {
    this.$refs.internetExplorerModal.show();
  }
};
</script>

<style scoped></style>
