<template>
  <div>
    <div v-if="isInternetExplorer">
      <InternetExplorer />
    </div>
    <div v-else id="app" class="pt-0">
      <app-header />
      <b-container class="pl-0 pr-0">
        <router-view />
      </b-container>
    </div>
  </div>
</template>

<script>
import Header from "./components/Header";

import "./assets/scss/app.scss";
import InternetExplorer from "./views/InternetExplorer";

export default {

  components: {
    InternetExplorer,
    "app-header": Header
  },

  mounted() {
    if (window.redirTarget) {
      this.$router.push(window.redirTarget);
    }
    if (window.verifyTestResultsData) {
      this.$router.push({
        path: "/verifyTestResults",
        query: { data: decodeURIComponent(window.verifyTestResultsData) }
      });
    }
  },

  computed: {
    isInternetExplorer() {
      return !!document.documentMode;
    }
  }
};
</script>

<style lang="scss">
//@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700");
//@import "node_modules/bootstrap/scss/bootstrap";
//@import "node_modules/bootstrap-vue/src/index";

html,
body {
  height: 100%;
  background-image: url("assets/huber_background-dark.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), url("assets/fonts/Nunito-VariableFont_wght.ttf") format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), url("assets/fonts/Nunito-VariableFont_wght.ttf") format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  src: local("Nunito"), url("assets/fonts/Nunito-VariableFont_wght.ttf") format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), url("assets/fonts/Nunito-VariableFont_wght.ttf") format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), url("assets/fonts/Nunito-VariableFont_wght.ttf") format('truetype');
  font-weight: 700;
}

#app {
  font-family: "Nunito", sans-serif;
  height: 100%;
  font-size: 0.8rem;
  font-weight: 400;
}

div.content {
  margin-left: 15px;
  margin-right: 15px;
}
.fsection-top {
  padding: 5px 36px;
  margin-top: 34px;
  margin-bottom: 0px;
  background-color: #17a2b8;
}
.edit-b {
  padding: 18px 18px;
  margin-top: 34px;
  margin-bottom: 0px;
  background-color: #17a2b8;
}

.fsection {
  padding: 40px;
  margin-top: 8px;
  margin-bottom: 0px;
  background-color: white;
}
.headingText {
  font-weight: bold;
  font-size: x-large;
}
.headingTextSignupForm {
  margin-top: 15px;
  margin-bottom: 10px;
}
.headingLine {
  margin-top: 5px;
}
.headingLeftLink {
  cursor: pointer;
  position: absolute;
  left: 10px;
  top: -25px;
  font-size: 10pt;
  color: #17a2b8;
}
.headingLeftLink:hover {
  color: #17a2b8;
}
.portalLink {
  cursor: pointer;
  font-size: 10pt;
  color: #17a2b8;
}
.portalLink:hover {
  color: #17a2b8;
}

/* GLOBAL STYLES */
.primary-bg-color:checked {
  background-color: #17a2b8;
}
.primary-bg-color::after {
  background-color: #17a2b8;
}
.primary-color {
  color: #17a2b8;
}
.primary-border-color:focus {
  border-color: #17a2b8;
}
.footer-back-link {
  margin-top: 10px;
  font-size: 14px;
  color: #17a2b8;
  cursor: pointer;
}

/* NON CODE-ACCESSABLE STYLES */
// Checkbox Switches
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

// Form Control Input Fields
.form-control:focus,
.custom-select:focus,
.input-tel.is-focused {
  border-color: #17a2b8 !important;
  box-shadow: 0 0 0 0.2rem rgb(23, 162, 184 / 0,25) !important;
}

// Portal Content Form
.pl-0 .pr-0 .container {
  padding-bottom: 50px;
}
// Change Language in Portal Dropdown Field
.locale-changer {
  margin-right: 10px;
}

//Test
.content {
  padding-top: 30px;
  padding-bottom: 30px;
}

.immunizedPatient td {
  background-color: #c3e6cb !important;
}

.immunizedPatientDisabled td {
  background-color: #c3e6cb !important;
  pointer-events: none !important;
}

.table-row-disabled {
  pointer-events: none !important;
  background-color: rgba(0, 0, 0, 0.15);
}

.cursor-pointer {
  cursor: pointer;
}
</style>
