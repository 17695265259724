const getInitialState = () => {
  return {
    accessToken: "",
    hasTests: false,
    username: "",
    patientId: "",
    qrCodeKey: "",
    extraConfig: {},
    isInvalid: false,
    isTokenExpired: false,
    patientGroup: null,
    assignedTestCenter: "",
    hideTestingInPortal: false,
    hideVaccinationInPortal: false,
    age: 0,
    hasPassword: false
  };
};

const state = getInitialState();

const mutations = {
  resetState(state) {
    Object.assign(state, getInitialState());
  },

  setAccessToken(state, payload) {
    state.accessToken = payload;
  },

  setLoginData(state, payload) {
    state.accessToken = payload.accessToken;
    state.hasTests = payload.hasTests;
    state.username = payload.username;
    state.patientId = payload.patientId;
    state.qrCodeKey = payload.qrCodeKey;
    state.extraConfig = payload.extraConfig || {};
    state.patientGroup = payload.patientGroup || {};
    state.assignedTestCenter = payload.assignedTestCenter;
    state.hideTestingInPortal = payload.hideTestingInPortal === true;
    state.hideVaccinationInPortal = payload.hideVaccinationInPortal === true;
    state.age = payload.age;
    state.hasPassword = payload.hasPassword;
    state.stayLoggedIn = payload.stayLoggedIn || false;
  },

  setIsInvalid(state, payload) {
    state.isInvalid = payload;
  },

  setIsTokenExpired(state, payload) {
    state.isTokenExpired = payload;
  },

  checkTokenExpired(state, error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error === "expiredToken"
    ) {
      state.isTokenExpired = true;
    }
  },

  setUserName(state, username) {
    state.username = username;
  },

  setHasPassword(state, hasPassword) {
    state.hasPassword = hasPassword;
  }
};

export default {
  namespaced: true,

  state,
  getters: {},
  actions: {},
  mutations
};
