import * as testTypeApi from "./testType.api";

const getInitialState = () => {
  return {
    testTypes: []
  };
};

const state = getInitialState();

const getters = {
  testTypes: state => state.testTypes
}

const mutations = {
  resetState(state) {
    Object.assign(state, getInitialState());
  },

  setTestTypes(state, payload) {
    state.testTypes = payload;
  }
}

const actions = {
  loadTestTypes({ commit }) {
    testTypeApi
      .getAllTestTypes()
      .then(res => {
        commit("setTestTypes", res.data);
      })
      .catch(() => {});
  }
}


export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};