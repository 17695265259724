export default {
    state: {
        captchaId: null
    },
    getters: {
        captchaId: state => state.captchaId
    },
    mutations: {
        setCaptchaId(state, payload) {
            state.captchaId = payload
        }
    },
    actions: {

    }
}
