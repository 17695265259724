import * as vaccinationGroupsApi from "./vaccinationGroups.api";

const getInitialState = () => {
  return {
    vaccinationGroups: []
  };
};

const state = getInitialState();

const getters = {
  vaccinationGroups: state => state.vaccinationGroups
};

const mutations = {
  resetState(state) {
    Object.assign(state, getInitialState());
  },

  setVaccinationGroups(state, payload) {
    state.vaccinationGroups = payload;
  }
};

const actions = {
  loadVaccinationGroups({ commit }) {
    vaccinationGroupsApi
      .getVaccinationGroups()
      .then(res => {
        commit("setVaccinationGroups", res.data);
      })
      .catch(() => {});
  }
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
