import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

// BootstrapVue Import(s)
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// Router & Store Import(s)
import router from "./router";
import store from "./store";
// VueI18N Import(s)
import en from "./locales/en.json";
import de from "./locales/de.json";
import tr from "./locales/tr.json";
import es from "./locales/es.json";
import ua from "./locales/ua.json";
import pt from "./locales/pt.json";
import VueI18n from "vue-i18n";
import { defaultLocale, localeOptions } from "./constants/config";
// PortalVue Import(s)
import PortalVue from "portal-vue";
import VueCompositionAPI from "@vue/composition-api";

Vue.use(BootstrapVue);
Vue.use(VueI18n);
Vue.use(IconsPlugin);
Vue.use(PortalVue);
Vue.use(VueCompositionAPI);
Vue.mixin({
  methods: {
    gShowSuccess(
      message,
      {
        title = this.$t("portal.success"),
        solid = true,
        autoHideDelay = 3000,
        appendToast = true
      } = {}
    ) {
      this.$root.$bvToast.toast(message, {
        title,
        variant: "success",
        solid,
        autoHideDelay,
        appendToast,
        toaster: "b-toaster-bottom-center"
      });
    },
    gShowError(
      message,
      {
        title = this.$t("portal.error"),
        solid = true,
        autoHideDelay = 3000,
        appendToast = true
      } = {}
    ) {
      this.$root.$bvToast.toast(message, {
        title,
        variant: "danger",
        solid,
        autoHideDelay,
        appendToast,
        toaster: "b-toaster-bottom-center"
      });
    },
    gShowWarning(
      message,
      {
        title = this.$t("common.warn"),
        solid = true,
        autoHideDelay = 2000,
        appendToast = true
      } = {}
    ) {
      this.$root.$bvToast.toast(message, {
        title,
        variant: "warning",
        solid,
        autoHideDelay,
        appendToast,
        toaster: "b-toaster-bottom-center"
      });
    }
  }
});

const messages = { en: en, de: de, tr: tr, es: es, pt: pt, ua: ua };
const locale =
  localStorage.getItem("currentLanguage") &&
  localeOptions.filter(x => x.id === localStorage.getItem("currentLanguage"))
    .length > 0
    ? localStorage.getItem("currentLanguage")
    : defaultLocale;
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: "de",
  messages
});

if (window.location.search) {
  const q = new URLSearchParams(location.search.replace("?", ""));

  if (q.has("qrData") && q.get("qrData").split("-SH-").length === 2) {
    const patientId = q.get("qrData").split("-SH-")[0];
    const qrCodeKey = q.get("qrData").split("-SH-")[1];
    window.redirTarget = `/results/${patientId}/${qrCodeKey}`;
  }

  if (window.location.href.includes("?verifyTestResults=")) {
    window.verifyTestResultsData = location.href.split(
      "?verifyTestResults="
    )[1];
  }
}

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
