
const getInitialState = () => {
  return {
    tenant: null,
    patientGroupId: null,
    selectedTestCenterId: null,
    testCenter: null,
    isOneTimePasswordValidated: false,
    isSignupPasswordValidated: false,
    isBookingSkipped: false,
    calendar: null,
    isCalendarAutoSelected: false,
    selectedSlotData: null,
    forceDisableBooking: false,
    lastBookingChangedTimestamp: Date.now(),
    calendars: []
  }
}

const state = getInitialState();

const getters = {
  isBookingRequired: state => {
    if (state.forceDisableBooking) {
      return false;
    }
    if (
      state.testCenter &&
      state.testCenter.calendarModuleOptions &&
      state.testCenter.calendarModuleOptions.bookingsActivated &&
      !state.isBookingSkipped
    ) {
      return true;
    }
    return false;
  }
}

const mutations = {
  resetState(state) {
    Object.assign(state, getInitialState());
  },

  setTenant(state, payload) {
    state.tenant = payload;
  },
  setPatientGroupId(state, payload) {
    state.patientGroupId = payload;
  },
  setSelectedTestCenterId(state, payload) {
    state.selectedTestCenterId = payload;
  },
  setTestCenter(state, payload) {
    state.testCenter = payload;
  },
  setIsOneTimePasswordValidated(state, payload) {
    state.isOneTimePasswordValidated = payload;
  },
  setIsSignupPasswordValidated(state, payload) {
    state.isSignupPasswordValidated = payload;
  },
  setIsBookingSkipped(state, payload) {
    state.isBookingSkipped = payload;
  },
  setCalendar(state, payload) {
    state.calendar = payload;
  },
  setIsCalendarAutoSelected(state, payload) {
    state.isCalendarAutoSelected = payload;
  },
  setSelectedSlotData(state, payload) {
    state.selectedSlotData = payload;
  },
  setForceDisableBooking(state, payload) {
    state.forceDisableBooking = payload;
  },
  setLastBookingChangedTimestamp(state, payload) {
    state.lastBookingChangedTimestamp = payload;
  },
  setCalendars(state, payload) {
    state.calendars = payload;
  }
}


export default {
  namespaced: true,

  state,
  getters,
  actions: {},
  mutations
};
