import Vue from "vue";
import Vuex from "vuex";
import testCenter from "./modules/testCenter/testCenter";
import vaccinationGroups from "./modules/vaccinationGroups/vaccinationGroups";
import testType from "./modules/testType/testType";
import vaccine from "./modules/vaccine/vaccine";
import patient from "./modules/patient/patient";
import signUp from "./modules/signUp/signUp";
import captcha from "./modules/captcha/captcha";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    resetAllStates({ commit }) {
      commit('patient/resetState');
      commit('signUp/resetState');
      commit('testCenter/resetState');
      commit('testType/resetState');
      commit('vaccinationGroups/resetState');
      commit('vaccine/resetState');
      commit('captcha/resetState');
    }
  },
  modules: {
    testCenter,
    vaccinationGroups,
    testType,
    vaccine,
    patient,
    signUp,
    captcha
  }
});
