import * as vaccineApi from "./vaccine.api";

const getInitialState = () => {
  return {
    vaccines: []
  };
};

const state = getInitialState();

const getters = {
  vaccines: state => state.vaccines
};

const mutations = {
  resetState(state) {
    Object.assign(state, getInitialState());
  },

  setVaccines(state, payload) {
    state.vaccines = payload;
  }
};

const actions = {
  loadVaccines({ commit }) {
    vaccineApi
      .getAllVaccines()
      .then(res => {
        commit("setVaccines", res.data);
      })
      .catch(() => {});
  }
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
