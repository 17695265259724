import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes =
  process.env.VUE_APP_IS_MAINTENANCE_MODE === "true"
    ? [
        {
          path: "*",
          component: () => import("./views/Maintenance")
        }
      ]
    : [
        {
          path: "/testImage/:testId",
          component: () => import("./views/TestImage")
        },
        {
          path: "/imprint",
          component: () => import("./views/legal/Imprint")
        },
        {
          path: "/signup",
          name: "signup",
          component: () => import("./views/SignupPicker"),
          props: route => ({
            ...route.params
          })
        },
        {
          path: "/signup/:testCenterId",
          component: () => import("./views/Signup"),
          children: [
            {
              path: "oneTimePassword",
              name: "oneTimePassword",
              component: () => import("./components/signup/OneTimePassword")
            },
            {
              path: "signupPassword",
              name: "signupPassword",
              component: () => import("./components/signup/SignupPassword")
            },
            {
              path: "calendar",
              name: "calendar",
              component: () => import("./components/signup/Calendar")
            },
            {
              path: "signupForm",
              name: "signupForm",
              component: () => import("./components/signup/SignupForm")
            }
          ]
        },
        {
          path: "/profileRemoved",
          component: () => import("./views/ProfileRemoved")
        },
        {
          name: "verify",
          path: "/verify/:verificationMethod/:patientId",
          component: () => import("./views/Verify")
        },
        {
          name: "verify",
          path: "/verify/:verificationMethod/:patientId/:code",
          component: () => import("./views/Verify")
        },
        {
          name: "payment",
          path: "/payment/:invoiceId",
          component: () => import("./views/Payment")
        },
        {
          path: "/username",
          name: "username",
          component: () => import("./views/Username")
        },
        {
          path: "/results/:username",
          component: () => import("./views/Login")
        },
        {
          path: "/results/:patientId/:qrKey",
          component: () => import("./views/Login")
        },
        {
          path: "/results/:patientId/:qrKey/test/:testId",
          component: () => import("./views/BirthdayTestResult")
        },
        {
          path: "/portal",
          name: "portal",
          component: () => import("./views/Portal"),
          redirect: { name: "portalOverview" },
          children: [
            {
              path: "profile",
              name: "profile",
              component: () => import("./components/portal/ChangeProfile"),
              props: route => ({
                ...route.params
              })
            },
            {
              path: "testResults",
              name: "testResults",
              component: () =>
                import("./components/portal/testResults/TestResults"),
              props: route => ({
                ...route.params
              })
            },
            {
              path: "testBooking",
              name: "testBooking",
              component: () =>
                import("./components/portal/testBooking/TestBooking"),
              props: route => ({
                ...route.params
              })
            },
            {
              path: "selfTest",
              name: "selfTest",
              component: () => import("./components/portal/selfTest/SelfTests"),
              props: route => ({
                ...route.params
              })
            },
            {
              path: "vaccinations",
              name: "vaccinations",
              component: () => import("./components/portal/Vaccinations"),
              props: route => ({
                ...route.params
              })
            },
            {
              path: "certificates",
              name: "certificates",
              component: () => import("./components/portal/Certificates"),
              props: route => ({
                ...route.params
              })
            },
            {
              path: "collectiveTesting",
              name: "collectiveTesting",
              component: () =>
                import(
                  "./components/portal/collectiveTesting/CollectiveTesting"
                ),
              props: route => ({
                ...route.params
              })
            },
            {
              path: "patientSettings",
              name: "patientSettings",
              component: () => import("./components/portal/PatientSettings")
            },
            {
              path: "",
              name: "portalOverview",
              component: () => import("./components/portal/PortalOverview")
            }
          ]
        },
        {
          path: "/verifyResultPDF",
          component: () => import("./views/VerifyTestResults")
        },
        {
          path: "/verifyVpdf",
          component: () => import("./views/VerifyVaccinationPDF")
        },
        {
          path: "/credentialVerification",
          component: () => import("./views/CredentialVerification")
        },
        {
          path: "*",
          redirect: "/signup",
          beforeEnter: (to, from, next) => {
            next();
          }
        },
        {
          name: 'passwordLost',
          path: "/passwordLost/:username?/:passwordLostKey?",
          component: () => import("./views/CredentialsLost")
        },
        {
          name: "kioskSignupSuccess",
          path: "/kioskSignupSuccess/:testCenterId/:patientId",
          component: () => import("./views/KioskSignupSuccess")
        }
      ];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
